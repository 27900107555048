<template>
    <div class="bg">
        <el-form label-width="80px" :model="info">
            <div>
                <el-form-item label="标题">
                    <el-input :rows="3" type="textarea" v-model="info.title" placeholder="请输入合集标题" maxlength="31" show-word-limit :clearable=true></el-input>
                </el-form-item>
                <el-form-item label="简介">
                    <el-input :rows="5" type="textarea" v-model="info.description" placeholder="请输入合集简介" maxlength="200" show-word-limit :clearable=true></el-input>
                </el-form-item>
            </div>
            <el-form-item label="合集封面">
                <div class="img-box">
                    <div class="img" :key="i" v-for="(m, i) in imgList" @click="selectFile()">
                        <img :src="rImg(m.url)" />
                        <i class="el-icon-error" @click.stop="rmImg(i)"></i>
                    </div>
                    <div class="add img" v-show="!imgList.length" @click="selectFile()">
                        <i class="el-icon-plus"></i>
                    </div>
                </div>
            </el-form-item>
            <el-form-item label="合集作品">
                <div>
                    <el-button type="primary" size="mini" @click.stop="drawer=drawer?false:true">添加作品</el-button>
                    <span style="color: #aaa;" v-show="list2.length">【拖曳可排序】</span>
                </div>
                <div ref="mix" id="mix"></div>
                <ul class="flag-ul flag-container" v-show="list2.length">
                    <li class="flag-list" :class="{'mv':sInfo.mi==index}" @dragstart="dragMove($event, index, m);" @drag="dragMove($event, index, m, 1)" @dragend="dragMove($event, index, m, 2);" draggable="true" v-for="(m, index) in list2">
                        <div class="flag-box">
                            <div class="img-box">
                                <el-image class="img" :class="{ 'video-img': m.video }" :src="getImg(m)" fit="cover">
                                    <div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div>
                                </el-image>
                            </div>
                            <div class="content">
                                <div class="title-box">
                                    <div class="title ellipsis-2">{{m.title || clearHtml(m.content) }}</div>
                                </div>
                                <div class="bottom flex-bt">
                                    <div class="num-box flex">
                                        <div class="icon-box">
                                            <i class="el-icon-view"></i>
                                            <span class="see">{{ returnNum(m.visit) }}</span>
                                        </div>
                                        <div class="icon-box" :class="{ active: m.isthumbsup }">
                                            <i class="iconfont icon-dianzan"></i>
                                            <span class="see">{{ returnNum(m.thumbsup) }}</span>
                                        </div>
                                        <div class="icon-box" :class="{ active: m.islover }">
                                            <i class="new-sc"></i>
                                            <span class="see">{{ returnNum(m.lover) }}</span>
                                        </div>
                                        <div class="icon-box">
                                            <i class="iconfont icon-xiaoxi"></i>
                                            <span class="see">{{ m.comments || 0 }}</span>
                                        </div>
                                    </div>
                                    <div class="op-box">
                                        <span class="op" @click.stop="rmMix(index)">从合集中删除</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li class="flag-list move" ref="move" id="move" v-show="sInfo.mi!=-1">
                        <div class="flag-box">
                            <div class="img-box">
                                <el-image class="img" :class="{ 'video-img': sInfo.val.video }" :src="getImg(sInfo.val)" fit="cover">
                                    <div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div>
                                </el-image>
                            </div>
                            <div class="content">
                                <div class="title-box">
                                    <div class="title ellipsis-2">{{ sInfo.val.title || clearHtml(sInfo.val.content) }}</div>
                                </div>
                                <div class="bottom flex-bt">
                                    <div class="num-box flex">
                                        <div class="icon-box">
                                            <i class="el-icon-view"></i>
                                            <span class="see">{{ returnNum(sInfo.val.visit) }}</span>
                                        </div>
                                        <div class="icon-box" :class="{ active: sInfo.val.isthumbsup }">
                                            <i class="iconfont icon-dianzan"></i>
                                            <span class="see">{{ returnNum(sInfo.val.thumbsup) }}</span>
                                        </div>
                                        <div class="icon-box" :class="{ active: sInfo.val.islover }">
                                            <i class="new-sc"></i>
                                            <span class="see">{{ returnNum(sInfo.val.lover) }}</span>
                                        </div>
                                        <div class="icon-box">
                                            <i class="iconfont icon-xiaoxi"></i>
                                            <span class="see">{{ sInfo.val.comments || 0 }}</span>
                                        </div>
                                    </div>
                                    <div class="op-box">
                                        <span class="op">从合集中删除</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="submit()">立即发布</el-button>
                <a href="#/admin_mix" style="margin-left: 20px;">
                    <el-button>取消</el-button>
                </a>
            </el-form-item>
        </el-form>
        <input type="file" @change="getImgs" ref="img" style="display: none" accept="image/*" />
        <el-drawer title="作品列表" :visible.sync="drawer" :direction="direction">
            <div class="drawer-box">
                <div class="drawer-head">
                    <el-input placeholder="请输入关键词，按回车搜索" v-model="kw" @change="getData(1)" clearable></el-input>
                    <el-checkbox style="margin: 10px 0;" v-model="must">只看可添加的作品</el-checkbox>
                </div>
                <div class="drawer-ul">
                    <mixList :list="list" :list2="list2" @addMix="addMix"></mixList>
                    <p class="more-btn" v-show="list.length&&list.length%20==0" @click="getData()">点击获取更多</p>
                </div>
            </div>
        </el-drawer>
    </div>
</template>
<script>
    import mixList from "@components/common/mix-list";
    import {
        mapGetters,
        mapMutations
    } from "vuex";
    let tInfo = {
        x1: 0, // offset x
        y1: 0, // offset y
        left: 0,
        top: 0, // 父元素距离顶部的距离 用以计算是第几个li
        h: 0, // 
        touch: 0,
        x2: 0, // 当前x
        y2: 0, // 当前y
        num: 0,
    }
    export default {
        components: {
            mixList,
        },
        data() {
            return {
                imgList: [],
                info: {
                    title: "",
                    description: "",
                },
                drawer: false, // 显示可选择作品列表
                kw: '', // 作品搜索词
                must: 0, // 1 仅显示可选择列表
                direction: 'rtl',
                list: [], // 显示列表
                list2: [], // 已选择的列表
                id: 0,
                sInfo: {
                    touch: 0,
                    val: {
                        masterimg: []
                    },
                    edit: 1,
                    mi: -1,
                },
            };
        },
        created() {
            // 在组件实例处理完所有与状态相关的选项后调用。
        },
        mounted() {
            this.id = this.$route.query.id;
            if (this.id) {
                // 编辑模式
                this.getDes()
            }
            // 在组件被挂载之后调用。
            this.getData(1);
            tInfo.top = this.$refs.mix.offsetTop;
            tInfo.left = this.$refs.mix.offsetLeft;
            if (window.innerWidth < 600) {
                this.direction = 'btt'
            }
        },
        methods: {
            // ...mapMutations([])
            // 获取合集详情
            async getDes() {
                this.$y_loading();
                let ret = await this.$y_ajax('api/Dynamic.Mix/info', {
                    id: this.id
                })
                if (ret.code == 200) {
                    let info = ret.data;
                    this.info = {
                        title: info.title,
                        description: info.description
                    }
                    this.imgList = [{
                        url: info.cover,
                        file: info.cover
                    }]
                    this.list2 = info.list;
                    let obj = {}
                    info.list.forEach(v => {
                        obj[v.dy_id] = 1;
                    })
                }
                this.$y_loadingClose();
            },
            // 添加作品
            addMix(param) {
                this.list2.push(this.list[param.i])
            },
            // 移除作品
            rmMix(i) {
                this.list2.splice(i, 1)
            },
            // 确定发布
            async submit() {
                if (!this.info.title) {
                    this.$y_msg("请输入合集标题");
                    return;
                }
                if (!this.imgList.length) {
                    this.$y_msg("请选择合集封面");
                    return;
                }
                let dy_ids = [];
                if (this.list2.length) {
                    this.list2.forEach(v => {
                        dy_ids.push(v.dy_id)
                    })
                }
                let obj = {
                    title: this.info.title,
                    description: this.info.description,
                }
                if (dy_ids) {
                    obj.dy_ids = dy_ids.join(',')
                }
                let url = 'api/Dynamic.Mix/create';
                if (this.id) {
                    obj.id = this.id;
                    url = 'api/Dynamic.Mix/update';
                }
                this.$y_loading();
                obj.cover = await this.$y_upImg(this.imgList[0].file);
                if (!obj.cover) {
                    this.$y_loadingClose();
                    return;
                }
                let ret = await this.$y_ajax(url, obj)
                this.$y_loadingClose();
                this.$y_msg(ret.msg);
                if (ret.code == 200) {
                    setTimeout(function() {
                        window.location.href = '#/admin_mix'
                    }, 1000)
                }
            },
            // 选择文件
            selectFile(type) {
                this.$refs.img.click();
            },
            // 转换图片
            getImgs() {
                let files = this.$refs.img.files;
                for (let i = 0, len = files.length; i < len; i++) {
                    let u = window.URL.createObjectURL(files[i]);
                    this.imgList = [{
                        url: u,
                        file: files[i],
                    }]
                }
            },
            // 移除图片
            rmImg(i) {
                this.imgList.splice(i, 1);
            },
            // 获取作品列表
            async getData(first) {
                let param = {
                    keyword: this.kw,
                    must: this.must,
                };
                if (first) {
                    param.page = 1;
                }
                let {
                    list,
                } = await this.$y_list('api/Dynamic.Mix/dylist', this.list, param);
                if (list) {
                    this.list = list;
                }
            },
            // 移动端
            touchLi(event, i, m, type) {
                let _this = this;
                if (!_this.sInfo.edit) return;
                tInfo.touch = 1;
                let x = event.changedTouches[0].pageX;
                let y = event.changedTouches[0].pageY;
                let e = event.currentTarget;
                if (!type) {
                    tInfo.x1 = x - e.offsetLeft - tInfo.left;
                    tInfo.y1 = y - e.offsetTop - tInfo.top;
                    this.moveM(x, y, e, m, i)
                } else {
                    this.moveM2(x, y, type)
                }
            },
            dragMove(event, i, m, type) {
                let _this = this;
                if (!_this.sInfo.edit || tInfo.touch) return;
                tInfo.top = this.$refs.mix.offsetTop;
                tInfo.left = this.$refs.mix.offsetLeft;
                let x = event.clientX;
                let y = event.clientY;
                let e = event.currentTarget;
                if (!type) {
                    tInfo.x1 = event.offsetX;
                    tInfo.y1 = event.offsetY;
                    this.moveM(x, y, e, m, i)
                } else {
                    this.moveM2(x, y, type)
                }
            },
            // pc 开始
            mouseLi(event, i, m) {
                let _this = this;
                if (!_this.sInfo.edit) return;
                if (tInfo.touch) return;
                let x = event.clientX;
                let y = event.clientY;
                let e = event.currentTarget;
                tInfo.x1 = event.offsetX;
                tInfo.y1 = event.offsetY;
                this.moveM(x, y, e, m, i)
            },
            // pc 移动&结束
            mouseLi2(event, type) {
                let _this = this;
                if (!_this.sInfo.edit) return;
                if (tInfo.touch) return;
                let x = event.clientX;
                let y = event.clientY;
                this.moveM2(x, y, type);
            },
            // 开始
            moveM(x, y, e, m, i) {
                let _this = this;
                let $move = document.querySelector('#move');
                $move.style.width = e.offsetWidth + 'px';
                tInfo.h = e.offsetHeight + 10;

                _this.sInfo.val = m;
                _this.sInfo.mi = i;

                tInfo.x2 = x;
                tInfo.y2 = y;
                $move.style.top = (y + 10) + 'px'; // 避免鼠标与虚假li重合
                $move.style.left = (x - tInfo.x1) + 'px';
                // $move.style.top = (y - tInfo.y1) + 'px';

            },
            // 移动及结束
            moveM2(x, y, type) {
                let _this = this;
                let $move = document.querySelector('#move');
                let num = Math.floor((tInfo.y2 + window.scrollY - tInfo.top) / tInfo.h);
                if (num < 0) {
                    num = 0;
                } else if (num >= _this.list2.length) {
                    num = _this.list2.length - 1;
                }
                if (type == 1) {
                    tInfo.num = num;
                } else {
                    num = tInfo.num
                }

                _this.rmList(_this.sInfo.mi, num);
                _this.sInfo.mi = num;
                tInfo.x2 = x;
                tInfo.y2 = y;
                $move.style.top = (y + 10) + 'px'; // 避免鼠标与虚假li重合
                $move.style.left = (x - tInfo.x1) + 'px';
                // $move.style.top = (y - tInfo.y1) + 'px';
                if (type == 2) {
                    _this.sInfo.mi = -1;
                }
            },
            // 重排位置
            rmList(s, e) {
                var _this = this;
                var b = _this.list2.splice(s, 1);
                _this.list2.splice(e, 0, b[0]);
            },
            // 获取图片
            getImg(m) {
                let img = m.video ? m.cover : m.masterimg[0];
                return this.rImg(img);
            },
        },
        computed: {
            // 计算属性
            // ...mapGetters([])
        },
        watch: {
            // 监听
            must() {
                this.getData(1)
            }
        },
    };
</script>

<style lang="less" scoped>
    @import '@assets/less/flag.less';
    @import '@assets/less/mylist.less';

    .mv {
        opacity: 0;
    }

    .move {
        position: fixed;
        box-shadow: 0 2px 5px 0px #333;
        background: #fff;
        z-index: 100;
        padding: 20px 10px;
        box-sizing: border-box;
    }

    .flag-list {
        cursor: pointer;
        transition: 0;

        .flag-box {
            flex-direction: row;

            .title {
                font-size: 14px;
            }
        }

        .video-img::after {
            font-size: 20px;
        }

        .img {
            width: 100px;
            height: 70px;
            border-radius: 7px;
        }

        .op {
            color: red;
            font-size: 12px;
        }
    }

    .bg {
        background: #fff;
        padding: 10px;
    }

    .drawer-box {
        padding: 0 15px 15px;
    }

    .flag-ul {
        padding: 10px 20px;
        background: #f3f3f3;
        border-radius: 7px;
    }

    .img-box {
        display: flex;
        flex-wrap: wrap;

        .img {
            width: 100px;
            height: 100px;
            border-radius: 10px;
            margin-right: 10px;
            margin-bottom: 10px;
            box-sizing: border-box;
            overflow: hidden;
            object-fit: cover;
            cursor: pointer;
            position: relative;

            &.add {
                border: 1px dashed #aaa;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .el-icon-error {
                position: absolute;
                top: 5px;
                right: 5px;
                color: #aaa;
                font-size: 20px;
            }

            &.video {
                width: auto;
                position: relative;

                video {
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                }

                .cover {
                    background: rgba(0, 0, 0, 0.5);
                    text-align: center;
                    color: #ccc;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    font-size: 14px;
                    width: 100%;
                }
            }
        }
    }

    .drawer-head {
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        background: #fff;
        z-index: 1;
    }

    .more-btn {
        text-align: center;
        color: #aaa;
        line-height: 50px;
        cursor: pointer;
    }

    #mix {
        position: fixed;
    }

    @media (max-width: 600px) {
        .flag-container {
            padding: 0 10px;

            .img-box {
                .img {
                    margin: 0;
                }
            }
        }

        .num-box {
            display: none;
        }

        /deep/.el-drawer.btt {
            height: 70% !important;
        }
    }
</style>